var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: "添加系统内任务",
        width: "1200px",
        dialogClass: "translate-modal",
      },
      on: { cancel: _vm.handleCancel },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          [
            _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
              _vm._v("\n        取消\n      "),
            ]),
            _c(
              "a-button",
              {
                key: "submit",
                attrs: { type: "primary" },
                on: { click: _vm.handleOk },
              },
              [_vm._v("\n        添加\n      ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { style: _vm.backCss },
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper search-form" },
            [
              _c(
                "a-form",
                {
                  attrs: { layout: "inline" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.searchQueryForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "a-row",
                    { attrs: { type: "flex", gutter: 16 } },
                    [
                      _c(
                        "a-col",
                        [
                          _c("span", [_vm._v("作品名称：")]),
                          _c("a-input", {
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "请输入作品名称" },
                            model: {
                              value: _vm.productName,
                              callback: function ($$v) {
                                _vm.productName = $$v
                              },
                              expression: "productName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        [
                          _c("span", [_vm._v("阶段名称：")]),
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                mode: "multiple",
                                "filter-option": _vm.filterOption,
                                placeholder: "请输入阶段名称",
                                "option-filter-prop": "label",
                              },
                              model: {
                                value: _vm.stageName,
                                callback: function ($$v) {
                                  _vm.stageName = $$v
                                },
                                expression: "stageName",
                              },
                            },
                            _vm._l(_vm.stages, function (stage) {
                              return _c(
                                "a-select-option",
                                {
                                  key: stage.stageName,
                                  attrs: { label: stage.stageName },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(stage.stageName) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", icon: "search" },
                              on: { click: _vm.searchQueryForm },
                            },
                            [_vm._v("搜索")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "statistics-menu-list" },
            [
              _vm._l(_vm.copyColumns, function (item, index) {
                return [
                  item.dataIndex != "evalResult"
                    ? _c(
                        "a-dropdown",
                        {
                          key: index,
                          ref: "statistics-" + index,
                          refInFor: true,
                          style: item.isNum ? "" : "display: none;",
                          attrs: { trigger: ["click"], placement: "topCenter" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "statistics" },
                            [
                              _vm._v("统计"),
                              _c("a-icon", { attrs: { type: "caret-up" } }),
                            ],
                            1
                          ),
                          _c(
                            "a-menu",
                            { attrs: { slot: "overlay" }, slot: "overlay" },
                            [
                              item.isNum
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.compute(
                                            "meanNum",
                                            item.dataIndex,
                                            index,
                                            "求和"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              求和\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.isNum
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.compute(
                                            "sumNum",
                                            item.dataIndex,
                                            index,
                                            "平均"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              平均\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "a-dropdown",
                        {
                          key: index,
                          ref: "statistics-" + index,
                          refInFor: true,
                          style: item.isNum ? "" : "display: none;",
                          attrs: { trigger: ["click"], placement: "topCenter" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "statistics" },
                            [
                              _vm._v("平均"),
                              _c("a-icon", { attrs: { type: "caret-up" } }),
                            ],
                            1
                          ),
                          _c(
                            "a-menu",
                            { attrs: { slot: "overlay" }, slot: "overlay" },
                            [
                              item.isNum
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.computeEval(
                                            "sumNumEval",
                                            item.dataIndex,
                                            index,
                                            "硬伤"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              硬伤\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.isNum
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.computeEval(
                                            "sumNumEval",
                                            item.dataIndex,
                                            index,
                                            "软伤"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              软伤\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.isNum
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.computeEval(
                                            "sumNumEval",
                                            item.dataIndex,
                                            index,
                                            "错翻"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              错翻\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.isNum
                                ? _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.computeEval(
                                            "sumNumEval",
                                            item.dataIndex,
                                            index,
                                            "修改率"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              修改率\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]
              }),
            ],
            2
          ),
          _c(
            "a-spin",
            { attrs: { spinning: _vm.tableLoading } },
            [
              _c(
                "a-dropdown",
                {
                  attrs: { placement: "bottomRight", trigger: ["contextmenu"] },
                  model: {
                    value: _vm.rightVisible,
                    callback: function ($$v) {
                      _vm.rightVisible = $$v
                    },
                    expression: "rightVisible",
                  },
                },
                [
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    _vm._l(_vm.columns, function (item, index) {
                      return _c(
                        "a-menu-item",
                        { key: item.dataIndex },
                        [
                          _c(
                            "a-checkbox",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.checkChange(index)
                                },
                              },
                              model: {
                                value: item.checked,
                                callback: function ($$v) {
                                  _vm.$set(item, "checked", $$v)
                                },
                                expression: "item.checked",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.title) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "vxe-table",
                    {
                      ref: "xTree",
                      attrs: {
                        border: "none",
                        "row-config": { isCurrent: true, isHover: true },
                        "column-config": { resizable: true },
                        "footer-method": _vm.footerMethod,
                        "tree-config": _vm.treeConfig,
                        "filter-config": {
                          remote: true,
                          iconNone: "iconfont icon-shaixuan icon",
                          iconMatch:
                            "iconfont icon-shaixuan icon filter-product-btn",
                        },
                        data: _vm.tableData,
                        "show-footer": "",
                      },
                      on: {
                        "checkbox-change": _vm.selectChangeEvent,
                        "checkbox-all": _vm.selectChangeEvent,
                        "cell-click": _vm.cellClickEvent,
                        "toggle-tree-expand": _vm.toggleExpandChangeEvent,
                        "filter-visible": _vm.filterVisible,
                        scroll: _vm.tableScroll,
                        "filter-change": _vm.filterChangeEvent,
                      },
                    },
                    [
                      _c("vxe-column", {
                        attrs: {
                          align: "center",
                          fixed: "left",
                          type: "checkbox",
                          minWidth: "80",
                          "show-header-overflow": "",
                        },
                      }),
                      _vm._l(_vm.copyColumns, function (record) {
                        return _c("vxe-column", {
                          key: record.dataIndex,
                          attrs: {
                            field: record.dataIndex,
                            title: record.title,
                            minWidth: record.width,
                            fixed:
                              record.dataIndex == "name"
                                ? "left"
                                : record.dataIndex == "action"
                                ? "right"
                                : "",
                            "tree-node": record.dataIndex == "name",
                            "show-overflow": "",
                            "show-header-overflow": record.dataIndex == "name",
                            filters:
                              record.dataIndex == "user" ||
                              record.dataIndex == "calcUnits" ||
                              record.dataIndex == "timeInterval" ||
                              record.dataIndex == "factor" ||
                              record.dataIndex == "money" ||
                              record.dataIndex == "imgNum" ||
                              record.dataIndex == "action"
                                ? null
                                : null,
                          },
                          scopedSlots: _vm._u(
                            [
                              record.dataIndex == "user"
                                ? {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        row.chapterSortAndStageName
                                          ? [
                                              row.editable
                                                ? _c(
                                                    "a-select",
                                                    {
                                                      staticStyle: {
                                                        margin: "-5px 0",
                                                        width: "150px",
                                                      },
                                                      attrs: {
                                                        showArrow: "",
                                                        placeholder: "选择用户",
                                                        "option-filter-prop":
                                                          "label",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        },
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.listEidt(
                                                            row
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: row.ownerId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "ownerId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.ownerId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.allUsers,
                                                      function (user) {
                                                        return _c(
                                                          "a-select-option",
                                                          {
                                                            key: user.userId,
                                                            attrs: {
                                                              label:
                                                                user.userName,
                                                              value:
                                                                user.userId,
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                width: "30px",
                                                                height: "30px",
                                                                "border-radius":
                                                                  "50%",
                                                                "margin-right":
                                                                  "10px",
                                                              },
                                                              attrs: {
                                                                src: user.avatar,
                                                                alt: "",
                                                              },
                                                            }),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  user.userName
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "align-items":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticStyle: {
                                                            width: "30px",
                                                            height: "30px",
                                                            "border-radius":
                                                              "50%",
                                                            "margin-right":
                                                              "10px",
                                                          },
                                                          attrs: {
                                                            src: row.avatar,
                                                            alt: "",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              row.userName
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                            ]
                                          : _vm._e(),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "completeStatus"
                                ? {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        row.chapterSortAndStageName
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                  },
                                                },
                                              },
                                              [
                                                _c("actions-tasks", {
                                                  attrs: {
                                                    propsParams: row,
                                                    isMyTask: true,
                                                    isMyTaskList: true,
                                                    isCalendar: false,
                                                    loadData: _vm.getTableData,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "calcUnits"
                                ? {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        row.chapterSortAndStageName
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getCalcUnits(
                                                      row.calcUnits
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "money"
                                ? {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(row.money) +
                                              _vm._s(
                                                _vm.moneyTypeMap[row.moneyType]
                                              )
                                          ),
                                        ]),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "name"
                                ? {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(row.name) +
                                              _vm._s(
                                                row.platformAndCoordinator &&
                                                  row.productSortAndName &&
                                                  row.hasChild &&
                                                  row.productTotal
                                                  ? "（" +
                                                      row.productTotal +
                                                      "）"
                                                  : ""
                                              )
                                          ),
                                        ]),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "totalScore"
                                ? {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        row.chapterSortAndStageName
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(row.totalScore || "") +
                                                  "\n              "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "action"
                                ? {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        row.platformAndCoordinator &&
                                        row.productSortAndName &&
                                        row.productTotal > 50
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: { width: "220px" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                  },
                                                },
                                              },
                                              [
                                                _c("a-pagination", {
                                                  attrs: {
                                                    size: "small",
                                                    showLessItems: true,
                                                    defaultPageSize: 50,
                                                    "default-current": 1,
                                                    total: row.productTotal - 0,
                                                  },
                                                  on: {
                                                    change: (
                                                      page,
                                                      pageSize
                                                    ) => {
                                                      _vm.treeTablePageChange(
                                                        page,
                                                        pageSize,
                                                        row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : row.chapterSortAndStageName
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-align": "left",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                  },
                                                },
                                              },
                                              [
                                                ((row.taskStatus != "等待" &&
                                                  row.taskStatus != "驳回") ||
                                                  (row.taskStatus == "驳回" &&
                                                    row.rejectTaskId)) &&
                                                row.applyTranslation &&
                                                row.applyReview
                                                  ? _c(
                                                      "a-dropdown",
                                                      {
                                                        attrs: {
                                                          trigger: ["click"],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "ant-dropdown-link",
                                                          },
                                                          [
                                                            row.stageName ==
                                                              "翻译" ||
                                                            row.stageName ==
                                                              "校对" ||
                                                            row.stageName ==
                                                              "终审"
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.goToTranslate(
                                                                            row
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "跳转翻译系统\n                    "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.goToFatchStraw(
                                                                            row
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "跳转查稿系统 "
                                                                    ),
                                                                  ]
                                                                ),
                                                            _c("a-icon", {
                                                              attrs: {
                                                                type: "down",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "a-menu",
                                                          {
                                                            attrs: {
                                                              slot: "overlay",
                                                            },
                                                            slot: "overlay",
                                                          },
                                                          [
                                                            _c("a-menu-item", [
                                                              row.stageName ==
                                                                "翻译" ||
                                                              row.stageName ==
                                                                "校对" ||
                                                              row.stageName ==
                                                                "终审"
                                                                ? _c(
                                                                    "a",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.goToFatchStraw(
                                                                              row
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "跳转查稿系统"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "a",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.goToTranslate(
                                                                              row
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "跳转翻译系统"
                                                                      ),
                                                                    ]
                                                                  ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : ((row.taskStatus !=
                                                      "等待" &&
                                                      row.taskStatus !=
                                                        "驳回") ||
                                                      (row.taskStatus ==
                                                        "驳回" &&
                                                        row.rejectTaskId)) &&
                                                    row.applyTranslation &&
                                                    !row.applyReview
                                                  ? _c(
                                                      "a",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.goToTranslate(
                                                              row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("跳转翻译系统")]
                                                    )
                                                  : ((row.taskStatus !=
                                                      "等待" &&
                                                      row.taskStatus !=
                                                        "驳回") ||
                                                      (row.taskStatus ==
                                                        "驳回" &&
                                                        row.rejectTaskId)) &&
                                                    !row.applyTranslation &&
                                                    row.applyReview
                                                  ? _c(
                                                      "a",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.goToFatchStraw(
                                                              row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("跳转查稿系统")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "evalResult"
                                ? {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        row.chapterSortAndStageName
                                          ? [
                                              _c(
                                                "span",
                                                [
                                                  _vm._l(
                                                    row.evalResult
                                                      .kpiValueErrors,
                                                    function (
                                                      item,
                                                      kpiViewindex
                                                    ) {
                                                      return [
                                                        item.count > 0
                                                          ? _c(
                                                              "a-tag",
                                                              {
                                                                key: kpiViewindex,
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      (item.type ==
                                                                      "SOFT"
                                                                        ? "软伤数"
                                                                        : item.type ==
                                                                          "HARD"
                                                                        ? "硬伤数"
                                                                        : item.type ==
                                                                          "ERROR"
                                                                        ? "错翻数"
                                                                        : item.type ==
                                                                          "SUPER_HARD"
                                                                        ? "超级硬伤数"
                                                                        : item.type ==
                                                                          "V1_HARD"
                                                                        ? "1改硬伤数"
                                                                        : item.type ==
                                                                          "V2_HARD"
                                                                        ? "2改硬伤数"
                                                                        : item.type ==
                                                                          "MORE_THAN_V2_HARD"
                                                                        ? "2改以上硬伤数"
                                                                        : item.type ==
                                                                          "SUPER_SOFT"
                                                                        ? "超级软伤"
                                                                        : "") +
                                                                        " : " +
                                                                        item.count
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    }
                                                  ),
                                                  row.evalResult
                                                    .hasKpiModifies &&
                                                  row.evalResult
                                                    .kpiModifyRatio > 0
                                                    ? _c("a-tag", [
                                                        _vm._v(
                                                          "\n                    修改率 :\n                    "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            style:
                                                              row.evalResult
                                                                .textAmount ||
                                                              !row.isLocal
                                                                ? ""
                                                                : "color:red",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                !row.isLocal
                                                                  ? row
                                                                      .evalResult
                                                                      .kpiModifyRatio +
                                                                      "%"
                                                                  : row
                                                                      .evalResult
                                                                      .textAmount
                                                                  ? row
                                                                      .evalResult
                                                                      .kpiModifyRatio +
                                                                    "%"
                                                                  : "X"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "finishStatus"
                                ? {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                row.finishStatus == "ADVANCE"
                                                  ? "提前"
                                                  : row.finishStatus ==
                                                    "ON_TIME"
                                                  ? "按时"
                                                  : row.finishStatus == "DELAY"
                                                  ? "拖稿"
                                                  : ""
                                              ) +
                                              "\n              "
                                          ),
                                        ]),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "imgNum"
                                ? {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        row.stageName == "给稿"
                                          ? _c("span", [
                                              _c("span", [
                                                _vm._v(
                                                  "原稿：" +
                                                    _vm._s(
                                                      (row.originalManuscriptPSDStatus &&
                                                        row
                                                          .originalManuscriptPSDStatus
                                                          .count) ||
                                                        0
                                                    )
                                                ),
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "15px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "JPG：" +
                                                      _vm._s(
                                                        (row.originalManuscriptJPGStatus &&
                                                          row
                                                            .originalManuscriptJPGStatus
                                                            .count) ||
                                                          0
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        row.stageName == "制作"
                                          ? _c("span", [
                                              _c("span", [
                                                _vm._v(
                                                  "完成稿：" +
                                                    _vm._s(
                                                      (row.finishManuscriptStatus &&
                                                        row
                                                          .finishManuscriptStatus
                                                          .count) ||
                                                        0
                                                    )
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "settleStatus"
                                ? {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                row.settleStatus == "NOT_SETTLE"
                                                  ? ""
                                                  : row.settleStatus ==
                                                    "SYSTEM_SETTLED"
                                                  ? "系统已结算"
                                                  : row.settleStatus ==
                                                    "USER_SETTLED"
                                                  ? "手动已结算"
                                                  : ""
                                              ) +
                                              "\n              "
                                          ),
                                        ]),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "deadline"
                                ? {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        row.editable
                                          ? _c("a-date-picker", {
                                              staticStyle: {
                                                margin: "-5px 0",
                                                "min-width": "145px",
                                              },
                                              attrs: {
                                                format: "YYYY-MM-DD HH:mm",
                                                valueFormat: "YYYY-MM-DD HH:mm",
                                                "show-time": {
                                                  format: "HH:mm",
                                                },
                                                placeholder: "请选择截止日期",
                                              },
                                              on: {
                                                ok: function ($event) {
                                                  return _vm.listEidt(row)
                                                },
                                              },
                                              model: {
                                                value: row.deadline,
                                                callback: function ($$v) {
                                                  _vm.$set(row, "deadline", $$v)
                                                },
                                                expression: "row.deadline",
                                              },
                                            })
                                          : [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(row.deadline) +
                                                  "\n              "
                                              ),
                                            ],
                                      ]
                                    },
                                  }
                                : record.dataIndex == "settlementDate"
                                ? {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        row.editable
                                          ? _c("a-month-picker", {
                                              staticStyle: {
                                                margin: "-5px 0",
                                                "min-width": "145px",
                                              },
                                              attrs: {
                                                disabled:
                                                  row.stageName != "交稿",
                                                format: "YYYY-MM",
                                                valueFormat: "YYYY-MM",
                                                "show-time": {
                                                  format: "YYYY-MM",
                                                },
                                                placeholder: "请选择结算月份",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.listEidt(row)
                                                },
                                              },
                                              model: {
                                                value: row.settlementDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "settlementDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.settlementDate",
                                              },
                                            })
                                          : [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(row.settlementDate) +
                                                  "\n              "
                                              ),
                                            ],
                                      ]
                                    },
                                  }
                                : record.dataIndex == "stageName"
                                ? {
                                    key: "filter",
                                    fn: function ({ $panel, column }) {
                                      return [
                                        _c(
                                          "a-spin",
                                          {
                                            staticStyle: {
                                              "padding-left": "10px",
                                              "padding-right": "50px",
                                              "min-height": "30px",
                                              "max-height": "200px",
                                              "overflow-y": "auto",
                                            },
                                            attrs: {
                                              spinning: _vm.filterLoading,
                                            },
                                          },
                                          [
                                            _c("a-tree", {
                                              attrs: {
                                                checkable: "",
                                                "tree-data": _vm.treeData,
                                              },
                                              on: {
                                                select: (
                                                  selectedKeys,
                                                  info
                                                ) => {
                                                  _vm.treeSelect(
                                                    record.dataIndex,
                                                    selectedKeys,
                                                    info
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.checkedStageNameKeys,
                                                callback: function ($$v) {
                                                  _vm.checkedStageNameKeys = $$v
                                                },
                                                expression:
                                                  "checkedStageNameKeys",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : null,
                              record.dataIndex == "deadline"
                                ? {
                                    key: "filter",
                                    fn: function ({ $panel, column }) {
                                      return [
                                        _c(
                                          "a-spin",
                                          {
                                            staticStyle: {
                                              "padding-left": "10px",
                                              "padding-right": "50px",
                                              "min-height": "30px",
                                              "max-height": "200px",
                                              "overflow-y": "auto",
                                            },
                                            attrs: {
                                              spinning: _vm.filterLoading,
                                            },
                                          },
                                          [
                                            _c("a-tree", {
                                              attrs: {
                                                defaultExpandedKeys:
                                                  _vm.defaultOpenKeys,
                                                checkable: "",
                                                "tree-data": _vm.treeData,
                                              },
                                              on: {
                                                expand: _vm.treeExpand,
                                                select: (
                                                  selectedKeys,
                                                  info
                                                ) => {
                                                  _vm.treeSelect(
                                                    record.dataIndex,
                                                    selectedKeys,
                                                    info
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.checkedDeadlineKeys,
                                                callback: function ($$v) {
                                                  _vm.checkedDeadlineKeys = $$v
                                                },
                                                expression:
                                                  "checkedDeadlineKeys",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "firstEndTime"
                                ? {
                                    key: "filter",
                                    fn: function ({ $panel, column }) {
                                      return [
                                        _c(
                                          "a-spin",
                                          {
                                            staticStyle: {
                                              "padding-left": "10px",
                                              "padding-right": "50px",
                                              "min-height": "30px",
                                              "max-height": "200px",
                                              "overflow-y": "auto",
                                            },
                                            attrs: {
                                              spinning: _vm.filterLoading,
                                            },
                                          },
                                          [
                                            _c("a-tree", {
                                              attrs: {
                                                defaultExpandedKeys:
                                                  _vm.defaultOpenKeys,
                                                checkable: "",
                                                "tree-data": _vm.treeData,
                                              },
                                              on: {
                                                expand: _vm.treeExpand,
                                                select: (
                                                  selectedKeys,
                                                  info
                                                ) => {
                                                  _vm.treeSelect(
                                                    record.dataIndex,
                                                    selectedKeys,
                                                    info
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.checkedFirstEndTimeKeys,
                                                callback: function ($$v) {
                                                  _vm.checkedFirstEndTimeKeys =
                                                    $$v
                                                },
                                                expression:
                                                  "checkedFirstEndTimeKeys",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "name"
                                ? {
                                    key: "filter",
                                    fn: function ({ $panel, column }) {
                                      return [
                                        _c(
                                          "div",
                                          { staticStyle: { padding: "10px" } },
                                          [
                                            _c("a-input", {
                                              staticStyle: {
                                                width: "150px",
                                                height: "24px",
                                                "line-height": "24px",
                                              },
                                              attrs: {
                                                placeholder: "请输入作品名称",
                                              },
                                              on: {
                                                pressEnter: function ($event) {
                                                  return _vm.inputFilter(
                                                    record.dataIndex
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.productName,
                                                callback: function ($$v) {
                                                  _vm.productName = $$v
                                                },
                                                expression: "productName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "settlementDate"
                                ? {
                                    key: "filter",
                                    fn: function ({ $panel, column }) {
                                      return [
                                        _c(
                                          "a-spin",
                                          {
                                            staticStyle: {
                                              "padding-left": "10px",
                                              "padding-right": "50px",
                                              "min-height": "30px",
                                              "max-height": "200px",
                                              "overflow-y": "auto",
                                            },
                                            attrs: {
                                              spinning: _vm.filterLoading,
                                            },
                                          },
                                          [
                                            _c("a-tree", {
                                              attrs: {
                                                defaultExpandedKeys:
                                                  _vm.defaultOpenKeys,
                                                checkable: "",
                                                "tree-data": _vm.treeData,
                                              },
                                              on: {
                                                expand: _vm.treeExpand,
                                                select: (
                                                  selectedKeys,
                                                  info
                                                ) => {
                                                  _vm.treeSelect(
                                                    record.dataIndex,
                                                    selectedKeys,
                                                    info
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.checkedSettlementDateKeys,
                                                callback: function ($$v) {
                                                  _vm.checkedSettlementDateKeys =
                                                    $$v
                                                },
                                                expression:
                                                  "checkedSettlementDateKeys",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "completeStatus"
                                ? {
                                    key: "filter",
                                    fn: function ({ $panel, column }) {
                                      return [
                                        _c(
                                          "a-spin",
                                          {
                                            staticStyle: {
                                              "padding-left": "10px",
                                              "padding-right": "50px",
                                              "min-height": "30px",
                                              "max-height": "200px",
                                              "overflow-y": "auto",
                                            },
                                            attrs: {
                                              spinning: _vm.filterLoading,
                                            },
                                          },
                                          [
                                            _c("a-tree", {
                                              attrs: {
                                                checkable: "",
                                                "tree-data": _vm.treeData,
                                              },
                                              on: {
                                                select: (
                                                  selectedKeys,
                                                  info
                                                ) => {
                                                  _vm.treeSelect(
                                                    record.dataIndex,
                                                    selectedKeys,
                                                    info
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.checkedStatusKeys,
                                                callback: function ($$v) {
                                                  _vm.checkedStatusKeys = $$v
                                                },
                                                expression: "checkedStatusKeys",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "settleStatus"
                                ? {
                                    key: "filter",
                                    fn: function ({ $panel, column }) {
                                      return [
                                        _c(
                                          "a-spin",
                                          {
                                            staticStyle: {
                                              "min-width": "170px",
                                              "padding-left": "10px",
                                              "padding-right": "50px",
                                              "min-height": "30px",
                                              "max-height": "200px",
                                              "overflow-y": "auto",
                                            },
                                            attrs: {
                                              spinning: _vm.filterLoading,
                                            },
                                          },
                                          [
                                            _c("a-tree", {
                                              attrs: {
                                                checkable: "",
                                                "tree-data": _vm.treeData,
                                              },
                                              on: {
                                                select: (
                                                  selectedKeys,
                                                  info
                                                ) => {
                                                  _vm.treeSelect(
                                                    record.dataIndex,
                                                    selectedKeys,
                                                    info
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.checkedSettleStatusKeys,
                                                callback: function ($$v) {
                                                  _vm.checkedSettleStatusKeys =
                                                    $$v
                                                },
                                                expression:
                                                  "checkedSettleStatusKeys",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "finishStatus"
                                ? {
                                    key: "filter",
                                    fn: function ({ $panel, column }) {
                                      return [
                                        _c(
                                          "a-spin",
                                          {
                                            staticStyle: {
                                              "padding-left": "10px",
                                              "padding-right": "50px",
                                              "min-height": "30px",
                                              "max-height": "200px",
                                              "overflow-y": "auto",
                                            },
                                            attrs: {
                                              spinning: _vm.filterLoading,
                                            },
                                          },
                                          [
                                            _c("a-tree", {
                                              attrs: {
                                                checkable: "",
                                                "tree-data": _vm.treeData,
                                              },
                                              on: {
                                                select: (
                                                  selectedKeys,
                                                  info
                                                ) => {
                                                  _vm.treeSelect(
                                                    record.dataIndex,
                                                    selectedKeys,
                                                    info
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.checkedFinishStatusKeys,
                                                callback: function ($$v) {
                                                  _vm.checkedFinishStatusKeys =
                                                    $$v
                                                },
                                                expression:
                                                  "checkedFinishStatusKeys",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "totalScore"
                                ? {
                                    key: "filter",
                                    fn: function ({ $panel, column }) {
                                      return [
                                        _c(
                                          "span",
                                          [
                                            _c("a-input-number", {
                                              staticStyle: {
                                                width: "80px",
                                                "text-align": "center",
                                              },
                                              attrs: {
                                                min: 0,
                                                placeholder: "起始值",
                                              },
                                              model: {
                                                value: _vm.minTotalScore,
                                                callback: function ($$v) {
                                                  _vm.minTotalScore = $$v
                                                },
                                                expression: "minTotalScore",
                                              },
                                            }),
                                            _vm._v(
                                              "\n                ~\n                "
                                            ),
                                            _c("a-input-number", {
                                              staticStyle: {
                                                width: "80px",
                                                "text-align": "center",
                                              },
                                              attrs: {
                                                min: 0,
                                                placeholder: "结束值",
                                              },
                                              model: {
                                                value: _vm.maxTotalScore,
                                                callback: function ($$v) {
                                                  _vm.maxTotalScore = $$v
                                                },
                                                expression: "maxTotalScore",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : record.dataIndex == "evalResult"
                                ? {
                                    key: "filter",
                                    fn: function ({ $panel, column }) {
                                      return [
                                        _c(
                                          "a-spin",
                                          {
                                            staticStyle: {
                                              "padding-left": "10px",
                                              "padding-right": "50px",
                                              "min-height": "30px",
                                              "max-height": "200px",
                                              "overflow-y": "auto",
                                            },
                                            attrs: {
                                              spinning: _vm.filterLoading,
                                            },
                                          },
                                          [
                                            _c("a-tree", {
                                              attrs: {
                                                checkable: "",
                                                "tree-data": _vm.treeData,
                                              },
                                              on: {
                                                select: (
                                                  selectedKeys,
                                                  info
                                                ) => {
                                                  _vm.treeSelect(
                                                    record.dataIndex,
                                                    selectedKeys,
                                                    info
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.checkedEvalResultKeys,
                                                callback: function ($$v) {
                                                  _vm.checkedEvalResultKeys =
                                                    $$v
                                                },
                                                expression:
                                                  "checkedEvalResultKeys",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                visible: _vm.confirmVisible,
                wrapClassName: "confirm-modal",
                bodyStyle: { padding: "32px", paddingBottom: "24px" },
                width: "416px",
                footer: null,
              },
              on: {
                cancel: function ($event) {
                  _vm.confirmVisible = false
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "16px",
                    display: "flex",
                    "align-items": "center",
                    "font-weight": "500",
                    color: "rgba(0, 0, 0, 0.85)",
                  },
                },
                [
                  _c("a-icon", {
                    staticStyle: {
                      "font-size": "22px",
                      "margin-right": "15px",
                      color: "orange",
                    },
                    attrs: { type: "question-circle" },
                  }),
                  _vm._v("是否加载已锁定状态的文件?\n      "),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    height: "62px",
                    display: "flex",
                    "align-items": "end",
                    "justify-content": "end",
                  },
                },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleConfimCancel(_vm.confirmTask)
                        },
                      },
                    },
                    [_vm._v("\n          不加载\n        ")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { "margin-left": "8px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfimOk(_vm.confirmTask)
                        },
                      },
                    },
                    [_vm._v("\n          加载\n        ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }